define("discourse/plugins/discourse-anonymous-moderators/discourse/components/anonymous-identity", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "discourse/lib/url", "@ember/template-factory"], function (_exports, _component, _component2, _service, _url, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldDisplay}}
    <a
      href={{this.link}}
      data-user-card={{this.dataUserCard}}
      class="anon-identity"
    >
      {{d-icon "user-secret"}}
      {{this.username}}
    </a>
  {{/if}}
  */
  {
    "id": "fJ2qrKUI",
    "block": "[[[41,[30,0,[\"shouldDisplay\"]],[[[1,\"  \"],[10,3],[15,6,[30,0,[\"link\"]]],[15,\"data-user-card\",[30,0,[\"dataUserCard\"]]],[14,0,\"anon-identity\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"user-secret\"],null]],[1,\"\\n    \"],[1,[30,0,[\"username\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-anonymous-moderators/discourse/components/anonymous-identity.hbs",
    "isStrictMode": false
  });
  class AnonymousIdentity extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get username() {
      return this.args.user?.custom_fields?.parent_user_username;
    }
    get link() {
      return (0, _url.userPath)(this.username);
    }
    get shouldDisplay() {
      return this.currentUser?.staff && this.username;
    }
    get dataUserCard() {
      if (this.args.noCard) {
        return false;
      }
      return this.username;
    }
  }
  _exports.default = AnonymousIdentity;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AnonymousIdentity);
});